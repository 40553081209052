import Vue from 'vue'
import VueRouter from 'vue-router'
//import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {title: 'のぞみクリニック築地 | 東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分 | 内科、胃腸科、腫瘍外科、オンライン診療、セカンドオピニオン'},
    component: () => import('../views/Home')
  },
  {
    path: '/information',
    meta: {title: 'お知らせ｜のぞみクリニック築地'},
    component: () => import('../views/Information')
  },
  {
    path: '/infoitem',
    meta: {title: "お知らせ｜のぞみクリニック築地"},
    component: () => import('../views/InfoItem')
  },
  {
    path: '/about',
    name: 'About',
    meta: {title: '当院について｜のぞみクリニック築地'},
    component: () => import('../views/About.vue')
  },
  {
    path: '/access',
    meta: {title: 'アクセス｜のぞみクリニック築地'},
    component: () => import('../views/Access')
  },
  {
    path: '/question',
    meta: {title: 'よくある質問｜のぞみクリニック築地'},
    component: () => import('../views/Question')
  },
  {
    path: "/sending",
    meta: {title: 'お問い合わせ｜のぞみクリニック築地'},
    component: () => import('../views/Sending')
  },
  {
    path: "/pcrcn",
    meta: {title: 'PCR検査(中国渡航)｜のぞみクリニック築地'},
    component: () => import('../views/PcrCN')
  },
  // {
  //   path: "/topic",
  //   meta: {title: 'トピックス｜のぞみクリニック築地'},
  //   component: () => import('../views/Topic')
  // },
  {
    path: '/department/asp',
    meta: {title: '高山病予防｜のぞみクリニック築地'},
    component: () => import('../views/department/Asp')
  },
  {
    path: '/department/dermatology',
    meta: {title: '腫瘍外科｜のぞみクリニック築地'},
    component: () => import('../views/department/Dermatology')
  },
  {
    path: '/department/second',
    meta: {title: 'セカンドオピニオン｜のぞみクリニック築地'},
    component: () => import('../views/department/Second')
  },
  {
    path: '/department/generalMedicine',
    meta: {title: '総合内科｜のぞみクリニック築地'},
    component: () => import('../views/department/generalMedicine')
  },
  {
    path: '/department/internal',
    meta: {title: '胃腸科｜のぞみクリニック築地'},
    component: () => import('../views/department/Internal')
  },
  {
    path: '/department/kampo',
    meta: {title: '漢方治療｜のぞみクリニック築地'},
    component: () => import('../views/department/Kampo')
  },
  {
    path: '/department/ReduceWeight',
    meta: {title: 'ダイエット｜のぞみクリニック築地'},
    component: () => import('../views/department/ReduceWeight')
  },
  // {
  //   path: '/department/pediatrics',
  //   meta: {title: '小児科｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Pediatrics')
  // },
  // {
  //   path: '/department/placenta',
  //   meta: {title: 'プラセンタ｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Placenta')
  // },
  // {
  //   path: '/department/sas',
  //   meta: {title: '糖尿病治療｜のぞみクリニック築地'},
  //   component: () => import('../views/department/Sas')
  // },
  {
    path: '/department/checkup',
    meta: {title: '健康診断｜のぞみクリニック築地'},
    component: () => import('../views/department/Checkup')
  },
  {
    path: '/department/telemedicine',
    meta: {title: 'オンライン診療｜のぞみクリニック築地'},
    component: () => import('../views/department/Telemedicine')
  },
  {
    path: '/department/fever',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/Fever')
  },
  {
    path: '/department/corona',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/Corona')
  },
  {
    path: '/department/diet',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/Diet')
  },
  {
    path:'/department/diet/price',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/DietPrice')
  },
  {
    path:'/department/diet/guide',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/DietGuide')
  },
  {
    path:'/department/diet/faq',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/DietFAQ')
  },
  {
    path:'/department/diet/faq',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/DietFAQ')
  },
  {
    path:'/department/placenta',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/Placenta')
  },
  {
    path:'/department/stemsup',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/Stemsup')
  },
  {
    path:'/department/cancer',
    meta:{title:'オンライン診療｜のぞみクリニック築地'},
    component:() => import('../views/department/Cancer')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 解决路由跳转后，不显示在首行
  scrollBehavior(to,from,savedPosition) {
    return savedPosition ||
    {
        top: to.meta?.scrollTop || 0,
        left: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  
  from.meta?.scrollTop && (from.meta.scrollTop = window.scrollY)

  if (to.meta.title) {
    document.title = to.meta.title
  }
  return next()
})

export default router
